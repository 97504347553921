import React, { useState, useEffect } from 'react';
import { getParamFromQuery } from '../utils/getParamFromQuery';
import { builder, BuilderComponent } from '@builder.io/react';
import { FunnelProvider } from '../context/FunnelContext';

import '../builder-settings';

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const FunnelPreview = props => {
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [path, setPath] = useState(null);
  const [funnelDesign, setFunnelDesign] = useState('design2');
  const [funnelUrl, setFunnelUrl] = useState(null);
  const [builderContentJson, setBuilderContentJson] = useState(null);
  const [currentCategory, setCurrentCategory] = useState('subscriptions');
  const [currentProduct, setCurrentProduct] = useState('subscription_0');
  const [tubIndex, setTubIndex] = useState(0);
  const [currentTubIndex, setCurrentTubIndex] = useState(0);
  const [productImageIndex, setProductImageIndex] = useState(0);
  const [frequency, setFrequency] = useState('1:month');
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [tubIndexChecked, setTubIndexChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);
  const [currentTub, setCurrentTub] = useState({});
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [loadingCover, setLoadingCover] = useState(true);
  const [showSavingsPopup, setShowSavingsPopup] = useState(false);
  const [submittedToCart, setSubmittedToCart] = useState(false);
  const [currentLayer, setCurrentLayer] = React.useState(`layer-0`);
  const [previousLayer, setPreviousLayer] = React.useState([]);
  const [localCartItems, setLocalCartItems] = React.useState([]);
  const [funnelUpdated, setFunnelUpdated] = React.useState(false);
  const [answers, setAnswers] = React.useState({});
  const [extraObjects, setExtraObjects] = useState({
    is_tub_index_checked: 'no',
    currentCategory
  });

  useEffect(() => {
    const id = getParamFromQuery('id');
    setPath(id);
    setFunnelUrl(id);
    builder
      .get('banners', { id: id })
      .promise()
      .then(res => {
        setCurrentCategory(res.data.currentCategory || 'subscriptions');
        setFunnelDesign(res.data.design || 'design_2');
        setBuilderContentJson(res);
        setLoadingPreview(false);
      });
  }, []);

  const context = {
    currentCategory: currentCategory,
    setCurrentCategory: setCurrentCategory,
    currentProduct: currentProduct,
    setCurrentProduct: setCurrentProduct,
    path: path,
    funnelUrl: funnelUrl,
    pageDesign: funnelDesign,
    tubIndex: tubIndex,
    setTubIndex: setTubIndex,
    currentTubIndex: currentTubIndex,
    productImageIndex: productImageIndex,
    setProductImageIndex: setProductImageIndex,
    setCurrentTubIndex: setCurrentTubIndex,
    frequency: frequency,
    setFrequency: setFrequency,
    bumpOfferChecked: bumpOfferChecked,
    setBumpOfferChecked: setBumpOfferChecked,
    bumpOfferIndex: bumpOfferIndex,
    setBumpOfferIndex: setBumpOfferIndex,
    currentTub: currentTub,
    setCurrentTub: setCurrentTub,
    subscriptionList: subscriptionList,
    setSubscriptionList: setSubscriptionList,
    userDetails: userDetails,
    setUserDetails: setUserDetails,
    deliveryDate: deliveryDate,
    setDeliveryDate: setDeliveryDate,
    calendarDate: calendarDate,
    setCalendarDate: setCalendarDate,
    loadingCover: loadingCover,
    setLoadingCover: setLoadingCover,
    showSavingsPopup: showSavingsPopup,
    setShowSavingsPopup: setShowSavingsPopup,
    submittedToCart: submittedToCart,
    setSubmittedToCart: setSubmittedToCart,
    currentLayer: currentLayer,
    setCurrentLayer: setCurrentLayer,
    previousLayer: previousLayer,
    setPreviousLayer: setPreviousLayer,
    answers: answers,
    setAnswers: setAnswers,
    extraObjects: extraObjects,
    setExtraObjects: setExtraObjects,
    tubIndexChecked: tubIndexChecked,
    setTubIndexChecked: setTubIndexChecked,
    localCartItems: localCartItems,
    setLocalCartItems: setLocalCartItems,
    funnelUpdated: funnelUpdated,
    setFunnelUpdated: setFunnelUpdated
  };

  if (loadingPreview) {
    return <>please wait, preview is still fetching the data...</>;
  }

  return (
    <FunnelProvider value={context}>
      <BuilderComponent model="banners" content={builderContentJson} />
    </FunnelProvider>
  );
};

export default FunnelPreview;
